import { useEffect, useState } from 'react';
import cn from 'classnames';
import axios from 'config/customAxios';
import { toast } from 'react-toastify';
import { Loader, RouteLeavingGuard } from 'components';
import { useHistory } from 'react-router-dom';
import { objectsAreNotEqual } from 'utils';
import { invertColor } from './helpers/colorsHelper';
import BrandingHeader from './components/BrandingHeader/BrandingHeader';
import BrandingFooter from './components/BrandingFooter/BrandingFooter';
import BrandingEdit from './components/BrandingEdit/BrandingEdit';
import BrandingPreview from './components/BrandingPreview/BrandingPreview';
import ModalBrandingClear from './components/ModalBrandingClear/ModalBrandingClear';
import ModalBrandingInfo from './components/ModalBrandingInfo/ModalBrandingInfo';
import BrandingNotAllowed from './components/BrandingNotAllowed/BrandingNotAllowed';
import styles from './Branding.module.scss';

const Branding = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [brandingInfo, setBrandingInfo] = useState({ init: null, modified: null });
  const [brandingStatus, setBrandingStatus] = useState(brandingInfo?.init?.isActive);
  const [brandingError, setBrandingError] = useState({ error: false, type: null });
  const [hexError, setHexError] = useState(false);
  const [showModalBrandingClear, setShowModalBrandingClear] = useState(false);
  const [showModalBrandingInfo, setShowModalBrandingInfo] = useState(false);
  const invertedColor = invertColor(brandingInfo?.modified?.backGroundColor);

  const history = useHistory();

  const isBrandingInfoChanged = objectsAreNotEqual({ initial: brandingInfo.init, modified: brandingInfo.modified });
  const isDisableClearBranding = !brandingInfo?.init?.backGroundColor
  && !brandingInfo?.init?.logo
  && !brandingInfo?.init?.isSetContactInfo
  && !brandingInfo?.init?.applyToDesktopConnectors;

  const toggleShowModalBrandingClear = () => setShowModalBrandingClear(!showModalBrandingClear);
  const toggleShowModalBrandingInfo = () => setShowModalBrandingInfo(!showModalBrandingInfo);

  useEffect(() => {
    setBrandingInfo((prevState) => ({
      modified: { ...prevState.modified, useInvertedColorScheme: invertedColor || false },
      init: brandingInfo.init,
    }));
  }, [brandingInfo.init, invertedColor]);

  useEffect(() => {
    const getBrandingInfo = () => {
      axios.get('/api/Company/GetBranding')
        .then(({ data }) => {
          setBrandingInfo({ init: data, modified: data });
          setBrandingStatus(data.isActive);
        })
        .catch((err) => {
          if (err.response.status === 403) {
            setBrandingError({ error: true, type: 'licensesSuspended' });
          } else if (err.response.status === 406) {
            setBrandingError({ error: true, type: 'licensesNotAvaliable' });
          } else {
            toast.error(err.response.data.message || err.message, 3000);
          }
        })
        .finally(() => setIsLoading(false));
    };
    getBrandingInfo();
    return () => setIsLoading(false);
  }, []);

  const toggleBrandingStatus = () => {
    setBrandingStatus(!brandingStatus);
    axios.post('/api/Company/ToggleBrandingStatus')
      .then(() => {
        setBrandingInfo((prevState) => ({
          modified: { ...prevState.modified, isActive: !prevState.modified.isActive },
          init: { ...prevState.init, isActive: !prevState.init.isActive },
        }));
        toast.success(`Company Branding has been ${brandingStatus ? 'disabled' : 'enabled'}.`, 3000);
      })
      .catch((err) => {
        setBrandingStatus(!brandingStatus);
        toast.error(err.response.data || err.message, 3000);
      });
  };

  if (isLoading) {
    return <div className={styles.branding}><Loader /></div>;
  }

  if (brandingError.error) {
    return <BrandingNotAllowed errorType={brandingError.type} />;
  }

  return (
    <div className={styles.branding}>
      <div className={styles.brandingWrap}>
        <div className={styles.brandingHeader}>
          <h3 className={styles.brandingTitle}>Branding</h3>
        </div>
        <BrandingHeader
          brandingStatus={brandingStatus}
          isDisableClearBranding={isDisableClearBranding}
          toggleBrandingStatus={toggleBrandingStatus}
          toggleShowModalBrandingClear={toggleShowModalBrandingClear}
        />
        <main className={cn(styles.brandingMain, { [styles.disabled]: !brandingInfo.modified.isActive })}>
          <BrandingEdit
            updateBrandingInfo={(data) => setBrandingInfo(data)}
            brandingInfo={brandingInfo}
            toggleShowModalBrandingInfo={toggleShowModalBrandingInfo}
            hexError={hexError}
            setHexError={setHexError}
          />
          <BrandingPreview brandingInfo={brandingInfo} invertedColor={invertedColor} />
        </main>
        <div className={cn({ [styles.brandingFooterDisabled]: !brandingInfo.modified.isActive })}>
          <BrandingFooter
            brandingInfo={brandingInfo}
            updateBrandingInfo={(data) => setBrandingInfo(data)}
            isBrandingInfoChanged={isBrandingInfoChanged}
            hexError={hexError}
          />
        </div>
      </div>
      <RouteLeavingGuard
        when={isBrandingInfoChanged}
        navigate={(path) => history.push(path)}
        shouldBlockNavigation={() => isBrandingInfoChanged}
        modalConfig={{
          label: 'Leave Modal',
          title: 'Discard changes',
          message: 'Do you really want to leave and discard changes?',
          btnClose: 'Stay on Page',
          btnAccept: 'Leave',
        }}
      />
      {showModalBrandingInfo && (
        <ModalBrandingInfo
          isOpen={showModalBrandingInfo}
          onRequestClose={toggleShowModalBrandingInfo}
          updateBrandingInfo={(data) => setBrandingInfo(data)}
        />
      )}
      {showModalBrandingClear && (
        <ModalBrandingClear
          isOpen={showModalBrandingClear}
          onRequestClose={toggleShowModalBrandingClear}
          updateBrandingInfo={(data) => setBrandingInfo(data)}
        />
      )}
    </div>
  );
};

export default Branding;

import PropTypes from 'prop-types';
import axios from 'config/customAxios';
import cn from 'classnames';
import { Button, SwitchCheckbox, Link } from 'components';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { CopyIcon, ExternalLinkIcon, LinkIcon } from 'assets/img';
import { copyToClipboard } from 'utils';
import styles from './BrandingFooter.module.scss';

const BrandingFooter = ({ brandingInfo, updateBrandingInfo, isBrandingInfoChanged, hexError }) => {
  const [isUploading, setIsUploading] = useState(false);

  const copyLink = () => copyToClipboard(`https://${brandingInfo?.modified?.brandingHost}`, 'Link is copied');

  const updateBranding = () => {
    setIsUploading(true);
    const postData = brandingInfo.modified;
    axios.post('/api/Company/UpdateBranding', postData)
      .then(({ data }) => {
        updateBrandingInfo({ init: data, modified: data });
        toast.success('Company branding has been updated', 3000);
      })
      .catch((err) => toast.error(err.response.data.message || err.message, 3000))
      .finally(() => setIsUploading(false));
  };

  const cancelAllChanges = () => {
    updateBrandingInfo(() => ({
      modified: brandingInfo.init, init: brandingInfo.init,
    }));
  };

  const toggleApplyForConnectors = (e) => {
    updateBrandingInfo((prevState) => ({
      modified: { ...prevState.modified, applyToDesktopConnectors: e.target.checked }, init: brandingInfo.init,
    }));
  };

  return (
    <footer className={styles.footer}>
      <div className={styles.footerRow}>
        <div className={styles.footerLinks}>
          <Link href={`https://${brandingInfo?.modified?.brandingHost}`} external bold className={styles.footerLink}>
            <span className={styles.footerLinkIcon}><LinkIcon /> Branded Link</span>
          </Link>
          <span className={styles.footerLinkWrap}>
            <Link href={`https://${brandingInfo?.modified?.brandingHost}`} external className={styles.footerLink}><ExternalLinkIcon /></Link>
            <Button variant="link" icon={<CopyIcon />} onClick={copyLink} />
          </span>
        </div>
        <div className={styles.footerActions}>
          <div className={styles.footerActionsCheckbox}>
            <SwitchCheckbox id="apply-for-desktop-connectors" checked={brandingInfo?.modified?.applyToDesktopConnectors || false} onChange={toggleApplyForConnectors} />
            <p>Apply to Desktop Connectors</p>
          </div>
          <div className={styles.footerActionsButtons}>
            <Button variant="secondary" id="branding-cancel" size="40" isDisabled={!isBrandingInfoChanged} onClick={cancelAllChanges}>
              Cancel
            </Button>
            <Button
              variant="primary"
              id="branding-save"
              size="40"
              isDisabled={!isBrandingInfoChanged || hexError}
              isLoading={isUploading}
              onClick={updateBranding}
              className={cn(styles.footerActionsSave, { [styles.loading]: isUploading })}
            >
              Save
            </Button>
          </div>
        </div>
      </div>
    </footer>
  );
};

BrandingFooter.propTypes = {
  brandingInfo: PropTypes.object,
  updateBrandingInfo: PropTypes.func,
  isBrandingInfoChanged: PropTypes.bool,
  hexError: PropTypes.bool,
};

export default BrandingFooter;

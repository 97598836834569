import PropTypes from 'prop-types';
import cn from 'classnames';
import { Button, SwitchCheckbox } from 'components';
import styles from './BrandingHeader.module.scss';

const BrandingHeader = ({ brandingStatus, isDisableClearBranding, toggleBrandingStatus, toggleShowModalBrandingClear }) => (
  <header className={styles.header}>
    <div>
      <div className={styles.headerCheckbox}>
        <p className={styles.headerCheckboxTitle}>Branding</p>
        <SwitchCheckbox id="branding-toggle" checked={brandingStatus} onChange={toggleBrandingStatus} />
        <span className={cn(styles.headerCheckboxText, { [styles.active]: brandingStatus })}>
          {brandingStatus ? 'ON' : 'OFF'}
        </span>
      </div>
      <div className={styles.headerInfo}>Customize how your brand appears on TruGrid</div>
    </div>
    <Button
      variant="link"
      id="clear-branding"
      className={cn(styles.headerClear, { [styles.disabled]: isDisableClearBranding || !brandingStatus })}
      isDisabled={isDisableClearBranding || !brandingStatus}
      onClick={toggleShowModalBrandingClear}
    >
      Clear Branding
    </Button>
  </header>
);

BrandingHeader.propTypes = {
  brandingStatus: PropTypes.bool,
  isDisableClearBranding: PropTypes.bool,
  toggleBrandingStatus: PropTypes.func,
  toggleShowModalBrandingClear: PropTypes.func,
};

export default BrandingHeader;

import PropTypes from 'prop-types';
import { Button } from 'components';
import SetBackground from './SetBackground/SetBackground';
import UploadLogo from './UploadLogo/UploadLogo';
import styles from './BrandingEdit.module.scss';

const BrandingEdit = ({ updateBrandingInfo, brandingInfo, toggleShowModalBrandingInfo, hexError, setHexError }) => (
  <div className={styles.brandingEdit}>
    <UploadLogo updateBrandingInfo={updateBrandingInfo} brandingInfo={brandingInfo} />
    <SetBackground
      hexError={hexError}
      setHexError={setHexError}
      updateBrandingInfo={updateBrandingInfo}
      brandingInfo={brandingInfo}
    />
    <div className={styles.brandingEditInfo}>
      <div className={styles.brandingEditInfoTitle}>Branding Information</div>
      <Button className={styles.brandingEditInfoButton} id="edit-branding-info" variant="link" onClick={toggleShowModalBrandingInfo}>
        {brandingInfo?.modified?.isSetContactInfo ? 'Edit' : 'Add'}
      </Button>
    </div>
  </div>
);

BrandingEdit.propTypes = {
  updateBrandingInfo: PropTypes.func,
  brandingInfo: PropTypes.object,
  toggleShowModalBrandingInfo: PropTypes.func,
  hexError: PropTypes.bool,
  setHexError: PropTypes.func,
};

export default BrandingEdit;

import { Route, Switch, useRouteMatch } from 'react-router-dom';
import Branding from 'pages/Branding/Branding';
import Billing from './Billing/Billing';
import MyProfile from './MyProfile/MyProfile';
import Settings from './Settings/Settings';
import Subscriptions from './Subscriptions/Subscriptions';

const Profile = () => {
  const { url } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${url}/branding`} component={Branding} />
      <Route path={`${url}/billing`} component={Billing} />
      <Route path={`${url}/myprofile`} component={MyProfile} />
      <Route path={`${url}/settings`} component={Settings} />
      <Route path={`${url}/subscription`} component={Subscriptions} />
    </Switch>
  );
};

export default Profile;

import cn from 'classnames';
import { CircleCloseIcon } from 'assets/img';
import { Button } from 'components';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { convertHex, isColor } from 'pages/Branding/helpers/colorsHelper';
import styles from './SetBackground.module.scss';

const SetBackground = ({ updateBrandingInfo, brandingInfo, hexError, setHexError }) => {
  const [hexInput, setHexInput] = useState(brandingInfo?.modified?.backGroundColor?.slice(1) || '');

  useEffect(() => {
    setHexError(!isColor(hexInput ? `#${hexInput}` : '#1f2536') || hexInput?.length === 4);
  }, [hexInput, setHexError]);

  useEffect(() => {
    setHexInput(brandingInfo?.modified?.backGroundColor?.slice(1) || '');
  }, [brandingInfo?.modified?.backGroundColor]);

  const handleInputBackground = (e) => {
    const val = e.target.value.replace('#', '').slice(0, 6);
    setHexInput(val);
    updateBrandingInfo((prevState) => ({
      modified: { ...prevState.modified, backGroundColor: `#${val}` }, init: brandingInfo.init,
    }));
  };

  const updateBackgroundColor = (e) => {
    updateBrandingInfo((prevState) => ({
      modified: { ...prevState.modified, backGroundColor: e.target.value }, init: brandingInfo.init,
    }));
  };

  const onClearRemove = () => {
    setHexError(false);
    setHexInput('');
    updateBrandingInfo((prevState) => ({
      modified: { ...prevState.modified, backGroundColor: null }, init: brandingInfo.init,
    }));
  };

  return (
    <div className={styles.backgroundRow}>
      <div className={styles.backgroundLabel}>
        <p className={styles.backgroundLabelTitle}>Background Color</p>
        <p className={styles.backgroundLabelInfo}>Add backround to your branded pages</p>
      </div>
      <div>
        <div className={styles.backgroundWrap}>
          <label htmlFor="profile-background-color" className={cn(styles.backgroundButton, { [styles.error]: hexError })}>
            {brandingInfo?.modified?.backGroundColor?.slice(1) && brandingInfo?.modified?.backGroundColor?.slice(1).length > 0 ? (
              <span className={styles.backgroundButtonColor} style={{ backgroundColor: convertHex(brandingInfo?.modified?.backGroundColor?.slice(1)) }} />
            ) : (
              <span className={styles.backgroundButtonColor} style={{ background: 'linear-gradient(268.67deg, #1F2536 38.36%, #4169B8 172.77%)' }} />
            )}
            <div className={styles.backgroundButtonColorWrap}>
              <span style={{ color: '#848A92' }}>#</span>
              <input
                type="text"
                className={styles.backgroundButtonColorInput}
                onChange={handleInputBackground}
                value={hexInput}
                placeholder="TruGrid"
              />
            </div>
          </label>
          <input
            type="color"
            className={styles.backgroundInput}
            onChange={updateBackgroundColor}
            id="profile-background-color"
            name="profile-background-color"
            value={brandingInfo?.modified?.backGroundColor ? convertHex(brandingInfo?.modified?.backGroundColor.slice(1)) : '#1f2536'}
          />
          {!!brandingInfo?.modified?.backGroundColor && (
            <Button variant="link" id="clear-background-color" icon={<CircleCloseIcon />} className={styles.backgroundClear} onClick={onClearRemove} />
          )}
        </div>
      </div>
    </div>
  );
};

SetBackground.propTypes = {
  updateBrandingInfo: PropTypes.func,
  brandingInfo: PropTypes.object,
  hexError: PropTypes.bool,
  setHexError: PropTypes.func,
};

export default SetBackground;

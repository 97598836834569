import PropTypes from 'prop-types';
import { Modal } from 'components';
import { useState } from 'react';
import axios from 'config/customAxios';
import { toast } from 'react-toastify';
import styles from './ModalBrandingClear.module.scss';

const ModalBrandingClear = ({ isOpen, onRequestClose, updateBrandingInfo }) => {
  const [isUploading, setIsUploading] = useState(false);
  const clearBranding = () => {
    setIsUploading(true);
    axios.post('api/Company/ResetBranding')
      .then(({ data }) => {
        updateBrandingInfo({ init: data, modified: data });
        setIsUploading(false);
        toast.success('Branding is cleared', 3000);
        onRequestClose();
      })
      .catch((err) => toast.error(err.response.data || err.message, 3000));
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Clear Branding"
      actionFunc={clearBranding}
      actionLoading={isUploading}
      actionTitle="Clear"
      variantBtn="deleteOutline"
      cancelTitle="Cancel"
      cancelButtonClass={styles.modalCancel}
    >
      After confirming an action, logo, Background Color, Branding Information will be cleared
    </Modal>
  );
};

ModalBrandingClear.propTypes = {
  isOpen: PropTypes.bool,
  onRequestClose: PropTypes.func,
  updateBrandingInfo: PropTypes.func,
};

export default ModalBrandingClear;

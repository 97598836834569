/* eslint-disable jsx-a11y/no-autofocus */
import PropTypes from 'prop-types';
import cn from 'classnames';
import { useForm, useOutsideClick } from 'hooks';
import { Button, SwitchCheckbox } from 'components';
import axios from 'config/customAxios';
import { toast } from 'react-toastify';
import { useMemo, useRef, useState } from 'react';
import { CloseIcon, SocialFbIcon, SocialLiIcon, SocialTwIcon, SocialYtIcon } from 'assets/img';
import { truncateString } from 'utils';
import styles from '../ModalBrandingInfo.module.scss';

const ModalForm = ({ brandingContactInfo, onRequestClose, updateBrandingInfo }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [socialActive, setSocialActive] = useState('');
  const { formData, handleInputChange, handleClearValue } = useForm(brandingContactInfo);

  const socialInputRef = useRef();
  useOutsideClick(socialInputRef, () => socialActive && setSocialActive(''));

  const {
    contactInfo,
    address,
    city,
    state,
    country,
    zipCode,
    phoneNumber,
    emailAddress,
    website,
    showOnDesktopConnectors,
    facebookLink,
    linkedinLink,
    twitterLink,
    youtubeLink,
  } = formData;

  const objectsAreNotEqual = useMemo(() => JSON.stringify(brandingContactInfo) === JSON.stringify(formData), [brandingContactInfo, formData]);

  const contactInputs = [
    { name: 'address', value: address, placeholder: 'Address' },
    { name: 'city', value: city, placeholder: 'City' },
    { name: 'state', value: state, placeholder: 'State' },
    { name: 'country', value: country, placeholder: 'Country' },
    { name: 'zipCode', value: zipCode, placeholder: 'Zip Code' },
  ];

  const supportInputs = [
    { name: 'phoneNumber', value: phoneNumber, placeholder: 'Phone Number', maxLength: '32' },
    { name: 'emailAddress', value: emailAddress, placeholder: 'Email address' },
    { name: 'website', value: website, placeholder: 'Website' },
  ];

  const socialsInputs = [
    { name: 'facebookLink', value: facebookLink, placeholder: 'Facebook', icon: <SocialFbIcon /> },
    { name: 'linkedinLink', value: linkedinLink, placeholder: 'Linkedin', icon: <SocialLiIcon /> },
    { name: 'twitterLink', value: twitterLink, placeholder: 'Twitter', icon: <SocialTwIcon /> },
    { name: 'youtubeLink', value: youtubeLink, placeholder: 'Youtube', icon: <SocialYtIcon /> },
  ];

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    axios.post('/api/Company/UpdateContactInfo', formData)
      .then(({ data }) => {
        updateBrandingInfo((prevState) => ({
          init: { ...prevState.init, isSetContactInfo: data },
          modified: { ...prevState.modified, isSetContactInfo: data },
        }));
        setIsLoading(false);
        onRequestClose();
        toast.success('Company branding has been updated', 3000);
      })
      .catch((err) => toast.error(err.response.data.message || err.message, 3000));
  };

  return (
    <form className={styles.form} onSubmit={handleSubmit}>
      <div className={styles.formTitle}>Contact</div>
      <div className={styles.formControl}>
        <div className={styles.formWrap}>
          <textarea
            onChange={handleInputChange}
            value={contactInfo || ''}
            id="contactInfo"
            maxLength="169"
            name="contactInfo"
            placeholder="Contact Information"
            className={cn(styles.formInput, styles.textarea)}
          />
          <div className={styles.formMaxLegth}>{contactInfo?.length || 0}<span>/169</span></div>
        </div>
        {contactInputs.map(({ name, value, placeholder }) => (
          <div key={name} className={cn(styles.formWrap)}>
            <input
              onChange={handleInputChange}
              value={value || ''}
              type="text"
              id={name}
              name={name}
              placeholder={placeholder}
              className={styles.formInput}
            />
          </div>
        ))}
      </div>
      <div className={styles.formTitle}>Support</div>
      <div className={styles.formControl}>
        {supportInputs.map(({ name, value, maxLength, placeholder, validate }) => (
          <div key={name} className={cn(styles.formWrap)}>
            <input
              type="text"
              onChange={handleInputChange}
              pattern={validate}
              value={value || ''}
              maxLength={maxLength}
              id={name}
              name={name}
              placeholder={placeholder}
              className={styles.formInput}
            />
          </div>
        ))}
      </div>
      <div className={styles.formTitle}>Connect with us</div>
      <div className={styles.formControl}>
        {socialsInputs.map(({ name, value, placeholder, icon }) => (
          <div key={name} className={cn(styles.formWrap)}>
            <div className={styles.formSocials}>
              <div>{icon}</div>
              <div className={styles.formSocialsLink}>
                <div className={styles.formSocialsName}>{placeholder}</div>
                {socialActive === name ? (
                  <div className={styles.formSocialsWrap} ref={socialInputRef}>
                    <input
                      autoFocus
                      onChange={handleInputChange}
                      value={value || ''}
                      id={name}
                      name={name}
                      className={styles.formSocialsInput}
                    />
                    {value && (
                      <Button variant="link" id={`remove-${name.toLowerCase()}`} icon={<CloseIcon />} className={styles.formSocialsRemove} onClick={() => handleClearValue(name)} />
                    )}
                  </div>
                ) : (
                  <div className={styles.formSocialsWrap} style={{ gap: 0 }}>
                    <Button variant="link" id={`add-${name.toLowerCase()}`} className={styles.formSocialsValue} onClick={() => setSocialActive(name)}>
                      {truncateString(value, false, 50) || 'Add link'}
                    </Button>
                    {value && (
                      <Button variant="link" id={`remove-${name.toLowerCase()}`} icon={<CloseIcon />} className={styles.formSocialsRemove} onClick={() => handleClearValue(name)} />
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className={cn(styles.formActions, styles.withSwitcher)}>
        <div className={styles.switcher}>
          <SwitchCheckbox id="showOnDesktopConnectors" name="showOnDesktopConnectors" checked={showOnDesktopConnectors} onChange={handleInputChange} />
          <p className={styles.switcherTitle}>Show on Desktop Connectors</p>
        </div>
        <div className={styles.formActionsButtons}>
          <Button
            variant="secondary"
            id="modal-cancel"
            size="40"
            onClick={onRequestClose}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            id="modal-action"
            className={cn(styles.formActionsButton, { [styles.loading]: isLoading })}
            isLoading={isLoading}
            isDisabled={objectsAreNotEqual}
            variant="primary"
            size="40"
          >
            Save
          </Button>
        </div>
      </div>
    </form>
  );
};

ModalForm.propTypes = {
  brandingContactInfo: PropTypes.object,
  onRequestClose: PropTypes.func,
  updateBrandingInfo: PropTypes.func,
};

export default ModalForm;

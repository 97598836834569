import PropTypes from 'prop-types';
import { Loader, Modal } from 'components';
import { useEffect, useState } from 'react';
import axios from 'config/customAxios';
import { toast } from 'react-toastify';
import ModalForm from './components/ModalForm';
import styles from './ModalBrandingInfo.module.scss';

const ModalBrandingInfo = ({ isOpen, onRequestClose, updateBrandingInfo }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [brandingContactInfo, setBrandingContactInfo] = useState();

  useEffect(() => {
    const getBrandingContactInfo = () => {
      axios.get('/api/Company/GetContactInfo')
        .then(({ data }) => setBrandingContactInfo(data))
        .catch((err) => toast.error(err.response.data.message || err.message, 3000))
        .finally(() => setIsLoading(false));
    };
    getBrandingContactInfo();
    return () => setIsLoading(false);
  }, []);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Add Branding Information"
      enableOverflow
    >
      {isLoading ? (
        <div className={styles.loaderWrap}><Loader /></div>
      ) : (
        <ModalForm brandingContactInfo={brandingContactInfo} onRequestClose={onRequestClose} updateBrandingInfo={updateBrandingInfo} />
      )}
    </Modal>
  );
};

ModalBrandingInfo.propTypes = {
  isOpen: PropTypes.bool,
  onRequestClose: PropTypes.func,
  updateBrandingInfo: PropTypes.func,
};

export default ModalBrandingInfo;

import PropTypes from 'prop-types';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import styles from './BrandingPreview.module.scss';
import LoginPreview from './components/LoginPreview/LoginPreview';
import WorkspacePreview from './components/WorkspacePreview/WorkspacePreview';

const BrandingPreview = ({ brandingInfo, invertedColor }) => (
  <Tabs selectedTabClassName={styles.tabListTabSelected}>
    <TabList className={styles.tabList}>
      <Tab className={styles.tabListTab}><span id="login-preview">Login Preview</span></Tab>
      <Tab className={styles.tabListTab}><span id="workspace-preview">Workspace Preview</span></Tab>
    </TabList>
    <TabPanel>
      <LoginPreview brandingInfo={brandingInfo} />
    </TabPanel>
    <TabPanel>
      <WorkspacePreview brandingInfo={brandingInfo} invertedColor={invertedColor} />
    </TabPanel>
  </Tabs>
);

BrandingPreview.propTypes = {
  brandingInfo: PropTypes.object,
  invertedColor: PropTypes.bool,
};

export default BrandingPreview;
